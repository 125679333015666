
import { Component } from 'vue-property-decorator'

import { StructureConfigurable } from '../../../../support/mixins'

import { CHECKOUT_VIEW_CONFIG_MAP, CHECKOUT_VIEW_KEY, CheckoutViewConfig } from '../../contracts'

/**
 * Checkout helper component containing checkout configuration
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<CheckoutAppConfig>({
  name: 'CheckoutAppConfig',
  created () {
    this.config = this.getComponentConfig(CHECKOUT_VIEW_KEY, { ...CHECKOUT_VIEW_CONFIG_MAP })
  }
})
export class CheckoutAppConfig extends StructureConfigurable {
  /**
   * Base checkout config
   */
  protected config!: CheckoutViewConfig

  public get displayFreeDeliveryValue (): boolean {
    return this.getConfigProperty('displayFreeDeliveryValue')
  }

  public get hasRegionField (): boolean {
    return this.getConfigProperty('hasRegionField')
  }

  public get truckIcon (): string | null {
    return this.getConfigProperty('truckIcon')
  }

  public get displayProducts (): boolean {
    return this.getConfigProperty('displayProducts')
  }

  public get shouldHaveBrandsWrapper (): boolean {
    return this.getConfigProperty('shouldHaveBrandsWrapper')
  }

  public get summaryHeading (): string | null {
    return this.getConfigProperty('summaryHeading')
  }

  public get requireRegionFieldInPayload (): boolean {
    return this.getConfigProperty('requireRegionFieldInPayload')
  }
}

export default CheckoutAppConfig
