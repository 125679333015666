







import { Component, Mixins } from 'vue-property-decorator'
import { CheckoutBase } from '../../base'
import { Inject } from '../../../../../support'
import { ISiteService, SiteServiceType } from '../../../../../contexts'

@Component<FreeDeliveryInfo>({
  name: 'FreeDeliveryInfo'
})
export class FreeDeliveryInfo extends Mixins(CheckoutBase) {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public get freeDeliveryFrom (): number | null {
    return this.siteService?.getProperty('freeDeliveryFrom') as number | null
  }
}
export default FreeDeliveryInfo
